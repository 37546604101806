
import ReactDOM from "react-dom";
import React from 'react'

const Ratings = () => {
  return (
    <div className="w-full flex place-content-center py-10">
        <div className="max-w-7xl justify-between px-2 items-center lg:flex">
            <div className="w-full lg:border-r border-r-gray-100 lg:w-5/12  2xl:w-4/12 pr-0 lg:pr-3 xl:pr-1">
                <h2 className="text-center lg:text-left text-3xl md:text-5xl 2xl:text-5xl text-gray-250">Highly rated by Global Software Review Platforms</h2>
            </div>
            <div className="md:flex justify-around mt-10 md:mt-10 pb-10 w-full lg:w-7/12 2xl:w-8/12 px-10 ">
                <div className="mb-10 md:mb-0 md:mr-5">
                    <div className="mb-5"><img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1657782520/gatsbycms/uploads/2022/07/G2-Rating.png" width="208" height="53" alt="G2 Logo" /></div>
                    <div className="w-max mx-auto flex justify-around items-center">
                        <div className="font-bold text-center starquant mr-2">4.6</div>
                        <div className="Stars logo-1" aria-label="Rating of this product is 4.6 out of 5."></div>
                    </div>
                </div>
                <div className="mb-10 md:mb-0 md:mr-5">
                    <div className="mt-2 mb-5"><img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim,h_43/v1657782525/gatsbycms/uploads/2022/07/Getapp-rating.png" width="229" height="43" alt="GetApp Logo"/></div>
                    <div className="w-max mx-auto flex justify-around items-center">
                        <div className="font-bold text-center starquant mr-2">4.5</div>
                        <div className="Stars logo-2" aria-label="Rating of this product is 4.4 out of 5."></div>
                    </div>
                </div>
                <div className="md:mr-5">
                    <div className="mb-5"><img className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/v1657782515/gatsbycms/uploads/2022/07/Capterra-rating.png" width="233" height="51" alt="Capterra Logo"/></div>
                    <div className="w-max mx-auto flex justify-around items-center">
                        <div className="font-bold text-center starquant mr-2">4.5</div>
                        <div className="Stars logo-3" aria-label="Rating of this product is 4.4 out of 5."></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
};

const renderRatings=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#ratings")
        if (id != null){
            ReactDOM.render(
                <Ratings />,
                id
            )
        }
    }
}

setTimeout(renderRatings, 100)
export default Ratings;